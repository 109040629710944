import React, { useState, useEffect } from 'react';
import { OpenAIClient, AzureKeyCredential } from "@azure/openai";

export const LitAzureAgent = async (inputquery) => {
   console.log("In LitAzureAgent limit 5 GPT3")
   console.log(inputquery) 
   const input = inputquery
   const currentYear = new Date().getFullYear();
   let systemprompt = "You are a helpful AI assistant that reviews the literature from scholarly websites.";
   systemprompt += "When you receive information about source, you provide hyperlinks formatted as first author's last name, followed by “et al.”, a comma and the year of publication. \n";
   systemprompt += `You have access to the latest information from the year ${currentYear} \n`;
   systemprompt += "You should ask for clarifying instructions from the user if something is not clear. \n";
   systemprompt +=  "Also, come up with 3 related questions a user can ask. \n";
   systemprompt += "Your response should be formatted as: \n";
   systemprompt += " **Sources**: Always provide hyperlinks \n";
   systemprompt += " **Answer**: Format as summary or table. Refer citations in the sources.\n";
   systemprompt += " **Related Questions**: ";
   
   const messages = [{ role: "system", content: systemprompt }];
   
   //const client = new OpenAIClient(process.env.REACT_APP_AZUREOPENAI4_CLIENT, new AzureKeyCredential(process.env.REACT_APP_AZUREOPENAI4_KEY));
   //const deploymentId = "omnigpt4"

   //const client = new OpenAIClient(process.env.REACT_APP_AZUREOPENAI_CLIENT, new AzureKeyCredential(process.env.REACT_APP_AZUREOPENAI_KEY));
   //const deploymentId = "omnigpt"
 
   const client = new OpenAIClient(process.env.REACT_APP_AZUREOPENAI_CLIENT, new AzureKeyCredential(process.env.REACT_APP_AZUREOPENAI_KEY));
   const deploymentId = "omnigpt35_16K"

	




   const functions = {
    doSemanticSearch: async function ({ data }) {
      try {
	const defaultYearRange = '2020-2024';
        const query = data.query;
	console.log("data");
	console.log(data);
	const yearRange = data.yearRange||defaultYearRange;
        const offset = 0;
        const limit = 5;
        const headers = new Headers();
        headers.append('X-API-KEY', process.env.REACT_APP_SEMANTIC_SCHOLAR_API_KEY);
	const apiUrl = `https://api.semanticscholar.org/graph/v1/paper/search?query=${encodeURIComponent(query)}&offset=${offset}&limit=${limit}&year=${yearRange}&fields=title,year,abstract,authors,url`;

        //const apiUrl = `https://api.semanticscholar.org/graph/v1/paper/search?query=${encodeURIComponent(query)}&offset=${offset}&limit=${limit}&year=${yearRange}&fields=title,year,abstract`;
        //const response = await fetch(apiUrl);
        const response = await fetch(apiUrl, {
  		headers: headers,
	});

        const responseData = await response.json();
        const papers = responseData.data;
	console.log("Get Authors");
	console.log(responseData)
        console.log(papers);
        let resultsString = '';
        papers.forEach(paper => {
          resultsString += JSON.stringify(paper) + '\n'; // You can adjust formatting as needed
        });
        return resultsString;
        } catch (error) {
        console.error('Error fetching data:', error);
        return '';
        }
       },
       };

  
   const doSemanticSearch =   {
     name: "doSemanticSearch",
     description: "Search the Semantic Scholar to get the most recent scientific publications.",
     parameters: {
       type: "object",
     properties: {
      data: {
        type: "object",
        properties: {
          query: {
            type: "string",
            description: "The query term used to search. It should contain important keywords. For example etch recipe SiO2."
          },
          yearRange: {
            type: "string",
            description: "The year range for the search. For example, '2000-2020' to search publications between the years 2000 and 2020. The current year is " + currentYear
          }
        },
        required: ["query"]
       }
      }
     }
    };


  const getPapers = async (input) => {
    console.log("input in getPapers")
    console.log(input)
    try{
    const response = await client.getChatCompletions(deploymentId, messages, {
      functions: [doSemanticSearch],
      functionCall:"auto",
      temperature: 0.0,
      maxTokens: 3200,
      n:1
    });
    return response;
    }
    catch (error) {
       console.error('Error fetching data:', error);
       return '';
    }
  };

   try {
     let i=0;
     messages.push({ role: "user", content: input });
     while (i<=2) {
       const response = await getPapers(messages);
       if (response.choices[0].finishReason === "stop") {
        return JSON.stringify({content:response.choices[0].message.content})
        break;}
       else if (response.choices[0].finishReason === "function_call"){
        const fnName = response.choices[0].message.functionCall.name;
        const args = response.choices[0].message.functionCall.arguments;
        const functionToCall = functions[fnName];
        const {data} = JSON.parse(args);
        const result = await functionToCall({ data });
        messages.push({role: "assistant",content: "null",function_call: {name: fnName,arguments: args,},});
        messages.push({role: "function",name: fnName,content: JSON.stringify({ result: result }),});
       }
      i=i+1;
       }
      }
  catch (error) {
    console.error('Error fetching from literature search agent', error);
    return null;
    }

  }

 
