import React from "react";
import "./index.css";
import TopAppBar from "./components/TopAppbar";
import BottomAppBar from "./components/BottomAppbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Home from "./views/homePage";
import Login from "./views/loginPage";
import Register from "./views/registerPage";
import ProtectedPage from "./views/ProtectedPage";
import LinkedInPage from "./LinkedInPage";

function App() {
  return (    
    <Router>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <AuthProvider>
	  <TopAppBar />
          <Switch>
          <PrivateRoute component={ProtectedPage} path="/protected" exact />
            <Route component={Login} path="/login" />
            <Route component={Register} path="/register" />
            <Route component={Home} path="/" />
	    <Route path="/linkedin" component={LinkedInCallback} />               <Route path="/test" component={LinkedInPage} />
          </Switch>
        </AuthProvider>
      <BottomAppBar />
      </div>
    </Router>
  );
}

export default App;
