import React, { useState, useEffect } from 'react';


export const PubChemSearch = async (representation,chemical) => {

    const propertiesString = "MolecularFormula,MolecularWeight,CanonicalSMILES,IsomericSMILES,InChI,InChIKey,IUPACName,Title,XLogP,ExactMass,MonoisotopicMass,TPSA,Complexity,Charge,HBondDonorCount,HBondAcceptorCount,RotatableBondCount,HeavyAtomCount,IsotopeAtomCount,AtomStereoCount,DefinedAtomStereoCount,UndefinedAtomStereoCount,BondStereoCount,DefinedBondStereoCount,UndefinedBondStereoCount,CovalentUnitCount,PatentCount,PatentFamilyCount,LiteratureCount,Volume3D,XStericQuadrupole3D,YStericQuadrupole3D,ZStericQuadrupole3D,FeatureCount3D,FeatureAcceptorCount3D,FeatureDonorCount3D,FeatureAnionCount3D,FeatureCationCount3D,FeatureRingCount3D,FeatureHydrophobeCount3D,ConformerModelRMSD3D,EffectiveRotorCount3D,ConformerCount3D,Fingerprint2D";


    try {
      const apiUrl = `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/${representation}/${chemical}/property/${propertiesString}/JSON`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      return JSON.stringify(data)
    }
    catch (error) {
      console.error('Error fetching data:', error);
      return '';
    }
  };

export const PubChemSearchImage = async (representation,chemical) => {

    try {
      const apiUrl = `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/${representation}/${chemical}/PNG`;
      const response = await fetch(apiUrl);
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl
    }
    catch (error) {
      console.error('Error fetching data:', error);
      return '';
    }
  };


export const MaterialsSynthesisSearch = async (keywords) => {
    try {
      const apiUrl = `https://beta.omnihelpfulai.com/home/materialssynthesis?keywords=${keywords}`;
      const headers = {
        accept: 'application/json',
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json();
      return JSON.stringify(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      return '';
    }
  };


export const MaterialsBatterySearch = async (working_ion) => {
    try {
      const apiUrl = `https://beta.omnihelpfulai.com/home/materialsbattery?working_ion=${working_ion}`;
      const headers = {
        accept: 'application/json',
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json();
      return JSON.stringify(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      return '';
    }
  };

  export const MaterialsExplorer = async (inputquery) => {
    try {
      const apiUrl = `https://beta.omnihelpfulai.com/home/materialsexplorer?inputquery=${inputquery}`;
      const headers = {
        accept: 'application/json',
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json();
      return JSON.stringify(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      return '';
    }
  };







