import React, { useState, useContext } from 'react';
import AWS from 'aws-sdk';
import { Tooltip, IconButton, Menu, MenuItem, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AuthContext from "../context/AuthContext";



const UploadForm = () => {
  const [file, setFile] = useState(null);
  const [folderFiles, setFolderFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [indexing, setIndexing] = useState(false);
  const [url, setUrl] = useState('');
  const [open, setOpen] = useState(false);
  const { user, authTokens} = useContext(AuthContext);
  const username = localStorage.getItem("username");
  const fileInputRef = React.createRef();
  const folderInputRef = React.createRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
    handleMenuClose();
  };

  const handleFolderButtonClick = () => {
    folderInputRef.current.click();
    handleMenuClose();
  };

  const handleUrlButtonClick = () => {
    setOpen(true);
    handleMenuClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleChooseAndUpload(selectedFile);
    } else {
      alert('No file selected.');
    }
  };

  const handleFolderChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 0) {
      setFolderFiles(selectedFiles);
      handleChooseAndUpload(selectedFiles);
    } else {
      alert('No files selected.');
    }
  };

  const handleChooseAndUpload = (target) => {
    if (target instanceof File) {
      uploadFile(target);
    } else if (Array.isArray(target) && target.length > 0) {
      uploadFolderFiles(target);
    } else if (url) {
      startIndexing(username);
    } else {
      alert('No target selected for upload.');
    }
  };

  const uploadFile = async (file) => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    setUploading(true);

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    const s3 = new AWS.S3();
    const params = {
      Bucket: 'omnilabreport',
      Key: `${username}/${file.name}`,
      Body: file
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      alert('File uploaded successfully.');
      setUploading(false);
      await startIndexing(username);
      alert('File indexed successfully.');
      setFile(null); // Clear the file after upload
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred while uploading the file.');
    } finally {
      setIndexing(false);
    }
  };

  const uploadFolderFiles = async (files) => {
    if (files.length === 0) {
      alert('Please select a folder first.');
      return;
    }

    setUploading(true);

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    const s3 = new AWS.S3();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const params = {
        Bucket: 'omnilabreport',
        Key: `${username}/${file.name}`,
        Body: file
      };

      try {
        const uploadResult = await s3.upload(params).promise();
        await startIndexing(username);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        alert(`An error occurred while uploading the file ${file.name}.`);
      }
    }

    alert('Folder files uploaded and indexed successfully.');
    setFolderFiles([]); // Clear the folder files after upload
    setUploading(false);
    setIndexing(false);
  };

  const startIndexing = async (username) => {
    setIndexing(true);
    try {
      const response = await fetch('https://beta.omnihelpfulai.com/home/retrievalmultimodal/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${authTokens?.access}`,
        },
        body: JSON.stringify({
          username: username
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        const data = await response.json();
        console.log('Response from backend:', data);
      }
    } catch (error) {
      console.error('Error sending username to backend:', error);
    }
  };


  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={folderInputRef}
        onChange={handleFolderChange}
        style={{ display: 'none' }}
        webkitdirectory="true"
        multiple
      />
      <Tooltip title="Click to start the ingestion process.">
        <IconButton 
          color="default" 
          onClick={handleMenuClick} 
          disabled={uploading}
          component="span"
        >
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleFileButtonClick}>
          <Tooltip title="Upload File">
            <InsertDriveFileIcon />
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={handleFolderButtonClick}>
          <Tooltip title="Upload Folder">
            <FolderOpenIcon />
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={handleUrlButtonClick}>
          <Tooltip title="Upload URL">
            <InsertLinkIcon />
          </Tooltip>
        </MenuItem>
      </Menu>

      {uploading && <p>Uploading...</p>}
      {indexing && <p>Indexing...</p>}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Connect to URL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the URL.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="url"
            label="URL"
            type="url"
            fullWidth
            value={url}
            onChange={handleUrlChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => startIndexing(username)} color="primary">
            Connect
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadForm;
