import { useEffect, useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import TestOpenAI from "../agents/MasterAzureAgent";


function ProtectedPage() {
  const { user, authTokens } = useContext(AuthContext);
  const { loginUser } = useContext(AuthContext);
  const [getdata, setgetData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${authTokens?.access}`,
        };
        const response = await fetch('https://beta.omnihelpfulai.com/home/user/info/', {
          method: 'GET',
          headers: headers,
        });
        const data = await response.json();
        setgetData(data.response);
      } catch (error) {
        console.error("Fetching data failed:", error);
        setgetData("Something went wrong");
      }
    };
    fetchData();
  }, [authTokens]); 
  console.log("user info")
  console.log(getdata)
  
  return (
    
    <div>
      <TestOpenAI/>
    </div>
  );
}

export default ProtectedPage;
