// functionList.js

const basicFunctionList = [
  {
    name: "doLitSearch",
    description: "Search the Semantic Scholar to get the most recent scientific publications.",
    parameters: {
      type: "object",
      properties: {
        data: {
          type: "object",
          properties: {
            inputquery: {
              type: "string",
              description: "The input query sentence by the user. For example, find papers on etching SiO2 from 2012."
            },
          },
          required: ["inputquery"]
        }
      }
    }
  }
];

export { basicFunctionList };

