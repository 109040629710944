// Help Document for Omni v1.0

import React from 'react';
import ReactMarkdown from 'react-markdown';

const markdownContent = `

# Welcome to Omni v1.0! 

## Introduction

#### Omni is an integrated platform for finding information, designing experiments and running simulations, **quickly and effortlessly**. 

Designed to help students, scientists, engineers, and technicians streamline their workflows, this guide provides detailed instructions and tips to ensure you make the most out of the platform's features. 

## Table of Contents

1. [Getting Started](#getting-started)
2. [Use Cases](#use-cases)
3. [Agents](#agents)
   - [Querying Scientific Publications](#querying-scientific-publications)
   - [Searching Custom PDFs](#searching-custom-pdfs)
   - [Accessing Materials Datasets](#accessing-materials-datasets)
   - [Designing Experiments](#designing-experiments)
7. [Tips for Effective Queries](#tips-for-effective-queries)
8. [Troubleshooting and Support](#troubleshooting-and-support)
9. [FAQs](#faqs)

*Note: Accessing materials datasets and designing experiments are premium features. Upgrade your account to unlock these advanced capabilities!*

## Getting Started

To begin using the Research Assistant Platform, follow these steps:

1. **Sign Up/Login**: Create an account or log in using your credentials.
2. **Discovery Hub**: Once logged in, you'll be directed to your Discovery Hub where you can access all agents.
3. **Navigation**: Use the menu to navigate between querying publications, searching PDFs, accessing datasets, and designing experiments.

## Use Cases

### Literature Review
To gather the most recent studies and papers on your topic of interest, helping you to stay up-to-date with the latest research developments:
- **Feature to Use**: [Querying Scientific Publications](#querying-scientific-publications)

### Troubleshooting
To quickly find and summarize important information within your own research documents, manuals, reports, and notes:
- **Feature to Use**: [Searching Custom PDFs](#searching-custom-pdfs)

### Materials Research
To find specific properties of materials needed for your experiments or product designs, enhancing the efficiency of your research process:
- **Feature to Use**: [Accessing Materials Datasets](#accessing-materials-datasets)

### Experimental Setup
To create a detailed and structured experimental plan, ensuring all necessary steps and variables are accounted for:
- **Feature to Use**: [Designing Experiments](#designing-experiments)

## Querying Scientific Publications

To query scientific publications:

1. **Navigate to the Publications Section**: Click on the "Publications" tab in the menu.
2. **Ask a Question**: In the search bar, type your question in natural language. For example, "What are the latest findings on CRISPR technology?"
3. **View Results**: The platform will display a list of relevant publications. You can click on any publication to view more details.

## Searching Custom PDFs

To search through your custom PDFs:

1. **Upload PDF**: Go to the "Custom PDFs" tab and upload your PDF documents.
2. **Query Your PDFs**: Type your question in the search bar. For example, "Summarize the key points of Chapter 3 in my uploaded document."
3. **Get Answers**: The platform will analyze your PDFs and provide the relevant information.

## Accessing Materials Datasets

To access materials datasets:

1. **Materials Section**: Click on the "Materials Datasets" tab.
2. **Query the Dataset**: Enter your question in natural language. For example, "Show me the thermal conductivity of titanium alloys."
3. **Data Results**: The platform will fetch data from the materials datasets and present it to you.

## Designing Experiments

To design experiments using the platform:

1. **Experiment Design Tab**: Navigate to the "Experiment Design" section.
2. **Describe Your Experiment**: Describe your experimental needs in natural language. For example, "Design an experiment to test the tensile strength of composite materials."
3. **Experiment Plan**: The platform will provide a detailed experimental plan based on your description.

## Tips for Effective Queries

- **Be Specific**: The more specific your question, the better the results.
- **Use Keywords**: Include important keywords relevant to your research.
- **Ask One Question at a Time**: For clarity, ask one question at a time.

## Troubleshooting and Support

If you encounter any issues or need assistance:

1. **Help Center**: Visit our Help Center for FAQs and troubleshooting guides.
2. **Contact Support**: Reach out to our support team via email at support@researchassistant.com or through the contact form on our website.
3. **Feedback**: We value your feedback. Please provide any suggestions or comments to help us improve the platform.

## FAQs

### How do I reset my password?
To reset your password, click on the "Forgot Password" link on the login page and follow the instructions.

### Can I upload multiple PDFs at once?
Yes, you can upload multiple PDFs simultaneously in the "Custom PDFs" section.

### What file formats are supported for uploading?
We support PDF, DOCX, and TXT file formats.

### How accurate are the experiment designs generated by the platform?
Our platform uses advanced algorithms to generate experiment designs, but we recommend reviewing and refining the designs to suit your specific needs.

### Is there a limit to the number of queries I can make?
There is no limit to the number of queries you can make. However, for optimal performance, we recommend not overloading the system with too many simultaneous queries.

### How does Omni work?
Omni is a suite of complex AI agents, combining and fine-tuning large language models (LLMs) with scientific datasets and other AI models.

### How does Omni keep the data secure?
Omni uses large language models deployed on the cloud. Therefore, no user data is sent to third-party LLM providers (OpenAI, Anthropic) for training. 

### How is Omni different from ChatGPT or GPTStore?
Omni is powered by multiple large language models, instead of a single large language model. It is designed specifically for technical users.



Thank you for choosing Omni. We are committed to making your research process more efficient and effective. Happy researching!

---

For further assistance, please contact our support team at info@reincarnateartificial.com

`;

const HelpContent = () => {
    return (
      <div>
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </div>
    );
  };
  
  export default HelpContent;
