import React, { useState, useEffect } from 'react';

export const Optimizer = async (inputquery) => {
    try {
      const apiUrl = `https://beta.omnihelpfulai.com/home/bayesopt?inputquery=${inputquery}`;
      const headers = {
        accept: 'application/json',
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json();
      return JSON.stringify(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      return '';
    }
  };