import React from 'react';
import Typography from '@mui/material/Typography';


export const docbasic = (
    <Typography color="inherit">
        <b>Search Literature </b> <br/> {"\n"}
        <i>Try asking:</i>{"\n"}
	<ul>
        <li>Summarize recent literature on topic.</li> {"\n"}
        <li>Create a table comparing methods, materials from literature.</li> {"\n"}
        <li>What were the key insights on a topic published between 2000 and 2005.</li> {"\n"}
	</ul>
    </Typography>
);

export const docadvanced = (
    <Typography color="inherit">
        <b>Optimize Experiments </b><br/> {"\n"}
        <i>Try asking:</i>{"\n"}
	<ul>
        <li>These are the recipes I tried while carrying out photolithography. What should I try next.</li> {"\n"}
        <li>I am trying to optimize my ML model. I have tried these sets of hyperparameters.</li> {"\n"}
        <li>I am designing a truss and want to optimize for cost and strength. These are the parameters I have tested. Can you suggest the next set of parameters?</li> {"\n"}
        </ul>
    </Typography>
);

export const docmaterials = (
    <Typography color="inherit">
        <b>Search Materials Genome </b> <br/>
        <i>Try asking:</i>{"\n"}
	<ul>
        <li>Can you find me stable materials with a density below 3.0 g/cm³ and a hexagonal crystal system?</li> {"\n"}
        <li>How can I synthesize Zirconium Dioxide (ZrO2) for use in ceramic industries?</li> {"\n"}
        <li>I am designing a new catalyst for a sustainable energy project. Can you help me find materials suitable for photocatalysis?</li> {"\n"}
	</ul>
    </Typography>
);


export const tooltipTitleOrg = (
  <React.Fragment>
    <Typography color="inherit">Tooltip with HTML</Typography>
    <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
    {"It's very engaging. Right?"}
  </React.Fragment>
);


export const tooltipTitle = (
  <React.Fragment>
    <Typography color="inherit"><i>Coming in future.</i></Typography>
  </React.Fragment>
);

export const docmemory = (
  <React.Fragment>
    <Typography color="inherit"><i>Turn on to customize agent response on provided data.</i></Typography>
  </React.Fragment>
);
