import { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TermsAndConditions from './TermsAndConditions';

const theme = createTheme();

function Register() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const { registerUser } = useContext(AuthContext);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!agreeToTerms) {
      alert('You must agree to the terms and conditions');
      return;
    }
    console.log(firstname);
    console.log(lastname);
    registerUser(firstname, lastname, email, username, password);
  };

  const  handleTermsClick = () => {
    setTermsDialogOpen(true);
  };

  const handleTermsDialogClose = () => {
    setTermsDialogOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
             <PersonAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Typography component="body1" variant="body1">Please use a work or an academic email id.</Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="first-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                  onChange={e => setFirstname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="last-name"
                  name="lastname"
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  onChange={e => setLastname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={e => {setEmail(e.target.value); setUsername(e.target.value);}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={e => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={agreeToTerms} onChange={e => setAgreeToTerms(e.target.checked)} color="primary" />}
                  label={<span>I have read and agree to the <span onClick={handleTermsClick} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>terms and conditions</span>.</span>}
                />
              </Grid>
            </Grid>
                    
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography component={Link} to="/login" variant="body2">
                  Already have an account? Sign in 
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        

              {/* Add Dialog for Info */}
              <Dialog open={termsDialogOpen} onClose={handleTermsDialogClose}>
                  <DialogContent>
                      <TermsAndConditions/>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleTermsDialogClose} color="primary">
                          Confirm
                      </Button>
                  </DialogActions>
              </Dialog>

                
      </Container>
    </ThemeProvider>
  );
}

export default Register;
