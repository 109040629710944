import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@material-ui/core/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import IconButton from '@mui/material/IconButton';
import HelpContent from './HelpContent1';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Box } from '@mui/material';


export default function BottomAppBar() {
 
  const [showPopup, setShowPopup] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  
  const handleHomeClick = () => {
  // Replace 'https://www.example.com' with the URL you want to open
   window.open('https://www.reincarnateartificial.com', '_blank');
  };

  const handleContactMailClick = () => {
  // Replace 'https://www.example.com' with the URL you want to open
   setShowPopup(!showPopup);
  };

  const  handleInfoClick = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
         
        <Typography variant="body2" align="left" component="div" sx={{ flexGrow: 1 }}>
            Copyright @ 2024 Reincarnate Inc. - All Rights Reserved
        </Typography>


            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>

              <IconButton color="inherit" size="large" sx={{ p: 0, mr: 1 }} onClick={handleInfoClick}>
                  <InfoIcon />
              </IconButton>
          
              {/* Add Dialog for Info */}
              <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose}>
                  <DialogContent>
                      <HelpContent />
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleInfoDialogClose} color="primary">
                          Close
                      </Button>
                  </DialogActions>
              </Dialog>
          
              <IconButton color="inherit" size="large" sx={{ p: 0, mx: 1 }} onClick={handleContactMailClick}>
                  <ContactMailIcon />
              </IconButton>
          
              <IconButton color="inherit" sx={{ p: 0 }} onClick={handleHomeClick}>
                  <HomeIcon />
              </IconButton>
          
          </Box>



           
        </Toolbar>
      </AppBar>

      {/* Popup message */}
      {showPopup && (
        <div style={{ position: 'fixed', bottom: '70px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}>
          Email info@reincarnateartificial.com
        </div>
      )}
    </React.Fragment>
  );
}
