import React, { useState, useContext, useEffect,useRef } from 'react';

//import { OpenAIApi, Configuration } from 'openai';
//import OpenAI from 'openai';
import AWS from 'aws-sdk';
import GPT3Tokenizer from 'gpt3-tokenizer';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; 
import InputAdornment from '@mui/material/InputAdornment';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import ExploreIcon from '@mui/icons-material/Explore';
import MenuIcon from '@mui/icons-material/Menu';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import Avatar from '@mui/material/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';

import { FormGroup, FormControlLabel, FormLabel} from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, MenuList, Select, Checkbox, ListItemText } from '@material-ui/core';
import { Button, Container } from '@material-ui/core';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


import CircularProgress from '@mui/material/CircularProgress';
import History from "../user/ConversationHistory";
import AuthContext from "../context/AuthContext";


import { OpenAIClient, AzureKeyCredential } from "@azure/openai";

import { docbasic, docadvanced, docmaterials, docmemory } from '../agentsdocumentation/documentation.js';

import { tooltipTitle } from '../agentsdocumentation/documentation.js';
import { basicFunctionList } from '../agentsdescriptions/basic.js';
import { materialScienceFunctionList } from '../agentsdescriptions/materialscience.js';
import { advancedFunctionList } from '../agentsdescriptions/advanced.js'
import { addMemoryFunctionList } from '../agentsdescriptions/addmemory.js'

import {LitAzureAgent} from "../agents/LiteratureSearchAzureAgent";
import {LitOpenAIAgent} from "../agents/LiteratureSearchOpenAIAgent";
//import {MaterialsDataAzureAgent} from "../agents/MaterialsDataAzureAgent";
//import {MaterialsDataAzureAgent} from "../agents/MaterialsDataPubChemAzureAgent";
import { MaterialsExplorer } from './MaterialsDataTools.js';
import {Optimizer} from './OptimizationTools.js'

import generateUserPrompt from '../report/generateUserPrompt';
import FileIngestion2 from '../report/FileIngestion2';
import welcomelogo from '../static/images/OmniTogether.png';

	const theme = createTheme();

	const Item = styled(Paper)(({ theme }) => ({
	    backgroundColor: theme.palette.mode === 'dark' ? '//1A2027' : '//fff',
	    ...theme.typography.body2,
	    padding: theme.spacing(1),
	    textAlign: 'center',
	    color: theme.palette.text.secondary,
	  }));

       const StyledDrawer = styled(Drawer)({
  width: 240,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 240,
    marginTop: 64,
    marginBottom: 64,
    height: `calc(100% - 128px)`
  },
  // Add custom style for the backdrop
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent', // This makes the backdrop transparent
  },
});

       const StyledDrawer1 = styled(Drawer)({
         width: 240,
         flexShrink: 0,
         '& .MuiDrawer-paper': {
         width: 240,
         marginTop: 64,
	 marginBottom: 64  
        },
       });

       const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
     backgroundColor: theme.palette.common.white,
     color: 'rgba(0, 0, 0, 0.87)',
     boxShadow: theme.shadows[1],
     fontSize: 11,
      },
     }));

     const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	  <Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
	  [`& .${tooltipClasses.tooltip}`]: {
	    backgroundColor: '//f5f5f9',
	    color: 'rgba(0, 0, 0, 0.87)',
	    maxWidth: 220,
	    fontSize: theme.typography.pxToRem(12),
	    border: '1px solid //dadde9',
	  },
	}));
	
	const UpgradeBox = styled(Box)(({ theme }) => ({
	  backgroundColor: 'white', 
          color: 'black', 
	  padding: theme.spacing(2),
	  borderRadius: theme.shape.borderRadius,
	  boxShadow: theme.shadows[1],
	  margin: theme.spacing(2, 0),
	  width: '20%',
  	  marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
	}));


	const TestOpenAI = () => {
	  const [inputQuery, setInputQuery] = useState('');
	  const [outputValue, setOutputValue] =  useState('');
	  const [userHistory, setUserHistory] = useState([]);
	  const { user, authTokens} = useContext(AuthContext);
	  const [userupgraded, setUserUpgraded] = useState(false);
	  const [userbeta, setIsBetaUser] = useState(true);
	  const [usermessages, setMessageCount] = useState(0);
	  const [userdata, setDataUsage] = useState(0);	

	  const [exceededMessageLimit, setExceededMessageLimit] = useState(false);
          const [exceededDataUsage, setExceededDataUsage] = useState(false);

		
	  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
	  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
	  const [snackbarOpen, setSnackbarOpen] = useState(false);
	  const [selectedOptions, setSelectedOptions] = useState(['Basic']);
	  const [allowedFunctionList, setAllowedFunctionList] = useState([]);
	  const [displayWelcome, setDisplayWelcome] = useState(true);

	  const openPlots =  false;
	  const currentYear = new Date().getFullYear();
	  //const systemprompt = "You are an AI assistant that helps scientists and engineers. You have access to latest information from year " + currentYear;
	  
	  //let systemprompt = "You are an orchestrator. You have access to agents that can search Literature, Materials Databases, User Uploaded Documents, Design experiments."
	  //systemprompt += "Available agents are provided as functions." 
	  //systemprompt += "Based on the input query, you need to determine which agent to call, and output the response of the agent in markdown." 
	  //systemprompt += "You have access to the conversation history." 
	  //systemprompt += "If the agent does not return a response, say you could not service the request."   
	  //systemprompt += "If no agent can be called or you can not answer the question using past conversation, try to answer the question as factually as possible." 

	  let systemprompt = "You are an AI research assistant that helps students, scientists, engineers, technicians." 
	  systemprompt += "You have access to tools that can search Literature, Materials Databases, User Uploaded Documents, Design experiments."
	  systemprompt += "Based on the input query, you need to determine which tool to call, and output the response in markdown."
	  systemprompt += "You have access to the conversation history.If the tool does not return a response, say you could not service the request."
	  systemprompt += "If no tool can be called or you can not answer the question using past conversation, try to answer the question as factually as possible."


		
	  const [messages,setMessages] = useState([{role:"system",content:systemprompt},]);
	  const [loading, setLoading] = useState(false);
	  const client = new OpenAIClient(process.env.REACT_APP_AZUREOPENAI4_CLIENT, new AzureKeyCredential(process.env.REACT_APP_AZUREOPENAI4_KEY));
	  const deploymentId = process.env.REACT_APP_AZUREOPENAI4_DEPLOYMENTID;
	  //const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
	  //const configuration = new Configuration({apiKey: openaiApiKey,});
	  //const openai = new OpenAIApi(configuration);

         
	  //const openai = new OpenAI({apiKey: openaiApiKey,dangerouslyAllowBrowser: true});
		
	  const tokenizer = new GPT3Tokenizer({ type: 'gpt3' });
	  const sendUserActivity = async (input) => {
	      let authTokens = localStorage.getItem("authTokens");
	      authTokens = JSON.parse(authTokens);
		  try {
		    console.log("Sending");
		    console.log(input);
		    // Set the authorization header with the JWT token
		    const headers = {
		      'Content-Type': 'application/json',
		      'Authorization': `JWT ${authTokens?.access}`,
		    };
	
		    const response = await fetch('https://beta.omnihelpfulai.com/home/user/activity/', {
		      method: 'POST',
		      headers: headers,
		      body: JSON.stringify({ input_data: input,file_size: 0 }),
		    });
	
		    if (response.ok) {
		      const responseData = await response.json();
		      console.log('User activity recorded successfully:', responseData);
		    } else {
		      console.error('Failed to record user activity');
		    }
		  } catch (error) {
		    console.error('Error recording user activity', error);
		  }
		};
	  
	 const toggleLeftDrawer = () => {
          setLeftDrawerOpen(!leftDrawerOpen);
          };

	 const toggleRightDrawer = () => {
          setRightDrawerOpen(!rightDrawerOpen);
          };

         //const upgraded = true;

	 const fetchUserData = async () => {
		 let authTokens = localStorage.getItem("authTokens");
	         authTokens = JSON.parse(authTokens);
		  try {
		    console.log("Sending request for user info.");
		    const headers = {
		      'Content-Type': 'application/json',
		      'Authorization': `JWT ${authTokens.access}`,
		    };
		
		    const response = await fetch('https://beta.omnihelpfulai.com/home/user/info/', {
		      method: 'GET',
		      headers: headers,
		    });
		
		    if (response.ok) {
		      const responseData = await response.json();
		      console.log('User info:', responseData);
		      return responseData; // You can return data to use it in the component state or props
		    } else {
		      console.error('Failed to get user data');
		      return null;
		    }
		  } catch (error) {
		    console.error('Error fetching user info:', error);
		    return null;
		  }
		};

	 const getUserInfo = async () => {
		    try {
		        const userDataResponse = await fetchUserData();
		
		        if (userDataResponse && userDataResponse.response) {
		            const userData = userDataResponse.response;
		
		            const { 
		                is_upgraded, 
		                message_count, 
		                is_betauser, 
		                data_usage, 
		                has_exceeded_message_limit, 
		                has_exceeded_data_usage 
		            } = userData;
		
		            setUserUpgraded(is_upgraded);
		            setMessageCount(message_count);
		            setIsBetaUser(is_betauser);
		            setDataUsage(data_usage);
		            setExceededMessageLimit(has_exceeded_message_limit);
		            setExceededDataUsage(has_exceeded_data_usage);
		
		            console.log("User info details:");
		            console.log("Exceeded Message Limit:", has_exceeded_message_limit);
		            console.log("User info:", userData);
		        } else {
		            console.error("Failed to fetch user info: response data is missing");
		        }
		    } catch (error) {
		        console.error("Failed to fetch user info:", error);
		    }
		};


	 
	const handleOptionChange = (event) => {
	    if (!userbeta) {
	        setSnackbarOpen(true);
	        return;
	    setSelectedOptions(event.target.value);
	    }

         const option = event.target.name; // Assuming the name attribute contains the option value
	    setSelectedOptions(prevSelected => {
	        if (prevSelected.includes(option)) {
	            return prevSelected.filter(selected => selected !== option);
	        } else {
	            return [...prevSelected, option];
	        }
	    });
		};


	 const handleSnackbarClose = (event, reason) => {
           if (reason === 'clickaway') {
             return;
           }
          setSnackbarOpen(false);
          };
	

	  const handleChange = (event) => {
	    setInputQuery(event.target.value);
	  };

	  const handleReset = () => {
	    setInputQuery('');
	    setOutputValue('');
	    setUserHistory([]);
	    setMessages([{role:"system",content:systemprompt},]);
	    setDisplayWelcome(true);
	  };

	

	const manageMessages = (messages, maxConversations) => {
	  const userConversations = [];
	  let currentConversation = [];

	  for (const message of messages) {
	    if (message.role === 'user') {
	      currentConversation = [message];
	      userConversations.unshift(currentConversation);
	    } else if (currentConversation.length > 0) {
	      currentConversation.push(message);
	    }
	  }

	  if (userConversations.length > maxConversations) {
	    const conversationsToRemove = userConversations.slice(maxConversations);
	    for (const conversation of conversationsToRemove) {
	      const startIndex = messages.indexOf(conversation[0]);
	      const endIndex = messages.indexOf(conversation[conversation.length - 1]);
	      messages.splice(startIndex, endIndex - startIndex + 1);
	    }
	  }
	};




	const CustomDataSearch = async (inputquery) => {
	    //const deploymentId = "omnigpt35_16K";
	    const deploymentId = process.env.REACT_APP_AZUREOPENAI4_DEPLOYMENTID;
	    let authTokens = localStorage.getItem("authTokens");
	    authTokens = JSON.parse(authTokens);
	    const customclient = new OpenAIClient(process.env.REACT_APP_AZUREOPENAI4_CLIENT, new AzureKeyCredential(process.env.REACT_APP_AZUREOPENAI4_KEY));
	    try {
	        const response = await fetch('https://beta.omnihelpfulai.com/home/querymultimodal/', {
	            method: 'POST',
	            headers: {
	                'Content-Type': 'application/json',
	                'Authorization': `JWT ${authTokens?.access}`,
	            },
	            body: JSON.stringify({ question: inputquery })
	        });
	
	        if (!response.ok) {
	            throw new Error(`HTTP error! Status: ${response.status}`);
	        }
		console.log("After RAG");
		console.log(response)
	
	        const data = await response.json();
	        const questionContext = data.message;
	        const userPrompt = await generateUserPrompt(inputquery, questionContext);
	        const systemPrompt = `You answer questions based on evidence. Always provide sources. Format answer in markdown. After answering add 3 related questions.`;
	        const messages = [{ role: 'user', content: systemPrompt }, { role: 'user', content: userPrompt }];
		    
	
	        const response1 = await customclient.getChatCompletions(deploymentId, messages, {
	            temperature: 0.0,
	            maxTokens: 650,
	            n: 1,
	        });

		console.log("After RAG answer");
		console.log(response1)
	
	        return JSON.stringify({ content: response1.choices[0].message.content });
	    } catch (error) {
	        console.error('Error with query', error);
	        return `Error: ${error.message}`;
	    }
	};
					

	const functions = {
        doLitSearch: async function({data}) {
              try {
                 //console.log(data)
                  const response = await LitAzureAgent(data.inputquery); //GPT 3.5 Azure
                 //const response = await LitOpenAIAgent(data.inputquery); //GPT 4 OpenAI

                 //console.log("Response from litagent.");
                 //console.log(response)
                 return response;
              }
              catch (error) {
                 console.log(error);
                 return null;
              }
            },
	doMaterialsDataSearch: async function({data}) {
              try {
                  const response = await MaterialsExplorer(data.inputquery); //GPT 3.5 Azure
                  //const answer = await response.json();
		  //return JSON.stringify(answer);
		  return response;
	      }
              catch (error) {
                 console.log(error);
                 return null;
              }
            },
	doOptimization: async function({data}) {
				try {
					const response = await Optimizer(data.inputquery); //GPT 3.5 Azure
					//const answer = await response.json();
			//return JSON.stringify(answer);
			return response;
			}
				catch (error) {
				   console.log(error);
				   return null;
				}			  },

   	doCustomDataSearch: async function({data}) {
                                try {
                                        const response = await CustomDataSearch(data.inputquery); //GPT 3.5 Azure
                                        //const answer = await response.json();
                        //return JSON.stringify(answer);
                        return response;
                        }
                                catch (error) {
                                   console.log(error);
                                   return null;
                                }
                          },

          };



	const optionsDataGenericAgents = [
           { mainOption: 'Basic', description: { tooltipTitle: docbasic }, subOptions: ['Search Literature', 'Analysis'] },
           { mainOption: 'Advanced',description: { tooltipTitle: docadvanced }, subOptions: ['Search Literature *', 'Analysis *'] },
           ];

	const optionsDataDomainAgents = [
           { mainOption: 'Material Science',description: { tooltipTitle: docmaterials }, subOptions: ['Search Databases', 'Generate Molecules'] },
	   { mainOption: 'Biology', description: { tooltipTitle: tooltipTitle }, subOptions: ['P1', 'P2'] },
           { mainOption: 'Photonics', description: { tooltipTitle: tooltipTitle }, subOptions: ['P1', 'P2'] },		
           ];


	const optionsDataIntegrations = [
           { mainOption: 'Talk to your data', description: { tooltipTitle: docmemory }, },
           ];

	

	const getFunctionListForOption = (option) => {
        switch (option) {
          case 'Basic':
            return basicFunctionList;
          case 'Advanced':
            return advancedFunctionList;
          case 'Material Science':
            return materialScienceFunctionList;
	  case 'Talk to your data':
            return addMemoryFunctionList;			
          //case 'Photonics':
          //  return photonicsFunctionList;
          default:
            return [];
          }
        };

         useEffect(() => {
         // Update allowedFunctionList based on selectedOptions
         const updatedFunctionList = selectedOptions.flatMap(option => 
            getFunctionListForOption(option)
         );
        setAllowedFunctionList(updatedFunctionList);
        }, [selectedOptions]);
	
	//const allowedFunctionList = [...defaultFunctionList];

	const getCompletion = async (messages) => {
	    let max_tokens = 2048;
	    const messagesStr = JSON.stringify(messages);
	    const encoded = tokenizer.encode(messagesStr);
	    const bpeLength = encoded.bpe.length;
	    console.log(`Length of encoded 'bpe' array: ${bpeLength}`);
	    
	    if (bpeLength > max_tokens) {
	      console.log("Tokens exceeded.")
	    }

	    const maxConversationsToRetain = 2;
	    manageMessages(messages, maxConversationsToRetain);
	    console.log(messages)

	    console.log("Agents selected.");
	    console.log(allowedFunctionList);

	    const response = await client.getChatCompletions(deploymentId, messages, {
		    functions: allowedFunctionList,
		    functionCall:"auto",
		    temperature: 0.0,
		    maxTokens: max_tokens,
		    n:1
	    });
	    return response;
	  }

	  const handleSubmit = async (event) => {
	    await getUserInfo();
	    event.preventDefault();
	    setLoading(true);
            setDisplayWelcome(false);
	    let inputquestion = "";
	    inputquestion= `${inputQuery}`;
	    userHistory.push({"input":inputquestion})
	    var inputprompt = `${inputquestion}`;
	    messages.push({role: "user",content:inputprompt,});
	    const useractivity =  sendUserActivity(inputprompt);
	    try {
	     await useractivity;
	    } catch (error) {
	    console.error('Error sending user activity:', error);
	    }
	    console.log("I have tried to send.");
	    let response;
	    try {
	      let i=0;
	      while (i<=1) {
	      console.log("Current value of i: " + i);
	      console.log("messages")
	      console.log(messages)
	      response = await getCompletion(messages);
	      console.log("response")
	      console.log(response)
	      if (response.choices[0].finishReason === "stop") {
		let newoutput;
		newoutput = response.choices[0].message.content;
		userHistory.push({"output":newoutput});
		console.log("history")
		console.log(userHistory);
		messages.push({
		  role: "assistant",
		  content: newoutput,});
		setOutputValue(response.choices[0].message.content);
		console.log("Final output")
		console.log(messages)
		break;}
	      else if (response.choices[0].finishReason === "function_call"){
		  const fnName = response.choices[0].message.functionCall.name;
		  const args = response.choices[0].message.functionCall.arguments;
		  const functionToCall = functions[fnName];
		  const {data} = JSON.parse(args); 
		  
		  console.log("with braces");
		  console.log({data});
		  console.log("inputquery");
		  console.log(data.inputquery);
		  data.inputquery = inputquestion;
		  console.log("modified with braces");
                  console.log({data});
		  //const modifieddata = JSON.stringify({ data });
		  //console.log(modifieddata);
		  const result = await functionToCall({ data });
		  console.log("result after agent call")
		  console.log("json")
		  console.log(typeof(result));
		  console.log(result)    

		  let newoutput1;
		  newoutput1=JSON.parse(result).content;
		  //newoutput1=JSON.parse(result);
		  userHistory.push({"output":newoutput1});
		  setOutputValue(newoutput1);
		  console.log("new output");
		  console.log(newoutput1);
		   
		  messages.push({role: "assistant",content: newoutput1,function_call: {name: fnName,arguments: args,},});
		  //messages.push({role: "function",name: fnName,content: JSON.stringify({ result: newoutput1 }),});
		  //console.log(messages)
		  break;
		  }
		     i=i+1;
		}
		const useractivity =  sendUserActivity(JSON.stringify({messages:messages}));
		try {
		  await useractivity;
		  } catch (error) {
		  console.error('Error sending user activity:', error);
		 }
	       console.log("I have tried to send.");
	    } catch (error) {
	      console.log("An error occurred: ", error);
	      setOutputValue("An error occurred: " + error.message);
	    }
	    setLoading(false);
	  };

	  useEffect(() => {
	    return () => {
	    };
	  }, []);

	 

	  return (
	    <section>
	    <ThemeProvider theme={theme}>
	    <CssBaseline />

	    <Box display="flex" justifyContent="flex-start" pl={3}> {/* pl={3} is padding-left with theme spacing of 3 */}
	      <IconButton 
	        color="default" 
	        onClick={toggleLeftDrawer} 
	        edge="start"
	        component="span"
	      >
	        <MenuIcon />
	      </IconButton>
	    </Box>



	     <StyledDrawer
              anchor="left"
              open={leftDrawerOpen}
              onClose={toggleLeftDrawer}
              variant="temporary" 
	      PaperProps={{ sx: { width: "20%" } }}
             >
	    
	     <div>
		     
       <div style={{ marginBottom: '16px' }}></div>  {/* This div adds space between the two forms */}

		     
          <FormControl fullWidth>
          <FormLabel id="options-label" component="legend" style={{ fontSize: '1.25rem', color: 'black',fontWeight: 'bold' }}>Generic Agents</FormLabel>
	    <FormGroup>
	        {optionsDataGenericAgents.map((option) => (
			                <HtmlTooltip key={option.mainOption} title={option.description.tooltipTitle || ''} placement='right'>
	            <FormControlLabel
	    key={option.mainOption}
	    control={<Checkbox checked={selectedOptions.includes(option.mainOption)} onChange={handleOptionChange} name={option.mainOption} />}
	    label={<Typography variant="body1" style={{ fontSize: '1.25rem' }}>{option.mainOption}</Typography>}/>
			</HtmlTooltip>
	        ))}
	    </FormGroup>
	</FormControl>

	<div style={{ marginBottom: '16px' }}></div>  {/* This div adds space between the two forms */}

	<FormControl fullWidth>
          <FormLabel id="options-label" component="legend" style={{ fontSize: '1.25rem', color: 'black',fontWeight: 'bold'  }}>Domain-Specific Agents</FormLabel>
	    <FormGroup>
	        {optionsDataDomainAgents.map((option) => (
			                <HtmlTooltip key={option.mainOption} title={option.description.tooltipTitle || ''} placement='right'>
	            <FormControlLabel
	    key={option.mainOption}
	    control={<Checkbox checked={selectedOptions.includes(option.mainOption)} onChange={handleOptionChange} name={option.mainOption} />}
	    label={<Typography variant="body1" style={{ fontSize: '1.25rem' }}>{option.mainOption}</Typography>}/>
			</HtmlTooltip>
	        ))}
	    </FormGroup>
	</FormControl>

			
	
	<div style={{ marginBottom: '16px' }}></div>  {/* This div adds space between the two forms */}

	          <FormControl fullWidth>
          <FormLabel id="options-label" component="legend" style={{ fontSize: '1.25rem', color: 'black',fontWeight: 'bold'  }}>Custom Agents</FormLabel>
	    <FormGroup>
	        {optionsDataIntegrations.map((option) => (
			                <HtmlTooltip key={option.mainOption} title={option.description.tooltipTitle || ''} placement='right'>
	            <FormControlLabel
	    key={option.mainOption}
	    control={<Checkbox checked={selectedOptions.includes(option.mainOption)} onChange={handleOptionChange} name={option.mainOption} />}
	    label={<Typography variant="body1" style={{ fontSize: '1.25rem' }}>{option.mainOption}</Typography>}/>
			</HtmlTooltip>
	        ))}
	    </FormGroup>
	</FormControl>
		
        </div>

            </StyledDrawer>
	    
	    <Box sx={{ bgcolor: 'background.paper', pt: 1, pb: 1 }}>
	     <Grid container justifyContent="center" alignItems="center">
		<Grid item xs={12}>

		   {displayWelcome && (
                   <Box textAlign="center" mb={2}>
                    <Typography variant="h4" gutterBottom>
                         Discovery Hub: Where Insights Converge
                    </Typography>
			
		      <img 
		        src={welcomelogo} 
		        alt="Descriptive alt text"
		        style={{ maxWidth: '400px', maxHeight: '400px' }} 
		      />
		
                   </Box>
                   )}

		   
 


		    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>

			<form onSubmit={handleSubmit} style={{ marginRight: '20px' }}>
			    <TextField
				id="UserInput"
				value={inputQuery}
				label="Ask Omni"
				variant="outlined"
				size="small"
		                multiline
		                rows={2}
		                onChange={handleChange}
				InputProps={{
				    endAdornment: (
					<InputAdornment position="start">
					    <PlayArrowIcon onClick={handleSubmit} style={{ cursor: 'pointer' }} />
					</InputAdornment>
				    ),
				}}
				style={{ width: '800px' }}
			    />
			</form>
			
                      
			<Tooltip title="Start new session">
			        <IconButton
			          color="default"
			          onClick={handleReset}
			          component="span"
			        >
			<RestartAltIcon />
			</IconButton>
			</Tooltip>
				    
			<FileIngestion2 />

	

				    
     

                {inputQuery && loading && (
                    <CircularProgress size={20} />
                )}
            </Box>

		       
                   <Box textAlign="center" mb={2}>
                    <Typography variant="caption" display="block" gutterBottom>
                        Omni is in private beta and may make mistakes
                    </Typography>
                   </Box>                

        </Grid>
		  
     </Grid>

		 
        {exceededMessageLimit && (
	        <UpgradeBox>
	          <Typography variant="body1">
	            We're thrilled that you love using Omni! Upgrade to premium and discover even more features designed just for you!
	          </Typography>
	        </UpgradeBox>
        )}

       {!exceededMessageLimit && outputValue && <History openplots={openPlots} history={userHistory} />}
    </Box>
     
    </ThemeProvider>
    </section>
  );
};

export default TestOpenAI;

