// functionList.js

const advancedFunctionList = [
    {
      name: "doOptimization",
      description: "Given the target provide an optimized set of parameters",
      parameters: {
        type: "object",
        properties: {
          data: {
            type: "object",
            properties: {
              inputquery: {
                type: "string",
                description: "The input query statement by the user. For example, I have tried 6 recipes. What is the next step to try while optimizing the photolithpgraphy process? Spin Speed (rpm)	Spin Time (s) Error Rate (%) 5000	30 -0.80"
              },
            },
            required: ["inputquery"]
          }
        }
      }
    }
  ];
  
  export { advancedFunctionList };
