import './index.css';
import React from 'react';
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import {LinkedIn} from 'react-linkedin-login-oauth2';


function LinkedInPage() {
  function handleFailure(e){
    console.log("In Failure")
    alert(e)
  }
  function handleSuccess(e){
    console.log("In success")
    console.log(e)
    fetch("/home/linkedin/",{
      method:"POST",
      body:JSON.stringify({'auth_token':e}),
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    .then((res)=>res.json())
    .then((res)=>{
      document.getElementById("email_id").innerText=res['email']
    })
  }
  return (
    <div>
      <LinkedIn
        clientId="86fqmqbocahmw7"                                     
        redirectUri={`http://184.72.5.56:3000/linkedin`}
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        scope="email profile openid"
        state="foobar"
        className='linkedin'>
        {({ linkedInLogin }) => (
          <img
            className='linkedin'
            onClick={linkedInLogin}
            src={linkedin}
            alt="Sign in with Linked In"

          />
        )}
      </LinkedIn>
      <div className='show_info'>
        <div>
          <label>Email Id: </label>
          <label id='email_id'></label>
        </div>
        <div>
          <label>Auth Token : </label>
          <label id='Auth_token'></label>
        </div>
      </div>
    </div>
  );
}

export default LinkedInPage;




