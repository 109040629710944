import React from 'react';
import ReactMarkdown from 'react-markdown';


const markdownContent = `
# Terms and Conditions

**Last Updated: May 22, 2024**

## 1. Introduction

Welcome to Reincarnate Inc (“Company”, “we”, “our”, “us”). These Terms and Conditions (“Terms”, “Terms and Conditions”) govern your use of our website located at www.reincarnateartificial.com (together or individually “Service”) operated by Reincarnate Inc.

By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.

## 2. Accounts

### 2.1 Account Creation

When you create an account with us, whether as an individual or on behalf of a company, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.

### 2.2 Responsibilities

If you are creating an account on behalf of a company, you represent and warrant that you have the authority to bind the company to these Terms. In such cases, the terms "you" and "your" will refer to both you as an individual and the company.

You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. You agree not to disclose your password to any third party.

### 2.3 Account Security

You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.

## 3. Use of the Service

### 3.1 Permitted Use

You agree to use the Service only for purposes that are permitted by these Terms and any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdictions.

### 3.2 Restrictions

You agree not to access (or attempt to access) the Service by any means other than through the interface that is provided by Reincarnate Inc, unless you have been specifically allowed to do so in a separate agreement with us.

## 4. Subscription and Billing

### 4.1 Subscriptions

Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.

### 4.2 Fee Changes

Reincarnate Inc, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.

### 4.3 Refunds

Except when required by law, paid Subscription fees are non-refundable.

## 5. Termination

We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.

Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.

## 6. Limitation of Liability

In no event shall Reincarnate Inc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party; and (v) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.

## 7. Governing Law

These Terms shall be governed and construed in accordance with the laws of Delaware, United States of America, without regard to its conflict of law provisions.

Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.

## 8. Changes

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.

## 9. Contact Us

If you have any questions about these Terms, please contact us at info@reincarnateartificial.com.

`;

const TermsAndConditions = () => {
    return (
      <div>
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </div>
    );
  };
  
export default TermsAndConditions;
