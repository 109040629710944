import React, { useState, useContext } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import AuthContext from "../context/AuthContext";
import { Button, TextField, Card, CardContent, Typography, Box } from "@mui/material";

export default function CheckoutForm() {
  const { user, logoutUser } = useContext(AuthContext);
  const { authTokens } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const username = localStorage.getItem("username");
  const [paymentStatus, setPaymentStatus] = useState(null);
  console.log("AUTH TOKENS IN CHECKOUT");
  console.log(authTokens);

  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);
   
    console.log("card");
    console.log(cardElement);

    const {paymentMethod, error} = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement
    });

    const data = {"email":localStorage.getItem("username"),"payment_method_id":paymentMethod.id}
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${authTokens?.access}`,
    };

   try {
    const response = await fetch('https://beta.omnihelpfulai.com/home/user/savecustomer/', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });
	
	   console.log("save customer response");
           console.log(await response.json());
	   setPaymentStatus('Upgrade successful. Please close the window.');
   }
   catch (error) {
      // Handle errors
      console.error('Error making payment:', error);
      setPaymentStatus('Payment failed. Please try again.');
    }


    //const paymentIntent = await response.json();
    
    //console.log("paymentIntent");
    //console.log(paymentIntent);

    // Confirm the payment
    //const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
    //     payment_method: {
    //    card: cardElement
    //  }
    // });

    //console.log("stripe result");
    //console.log(result);

    // if (result.error) {
    //  setError(result.error.message);
    // } else {
    //  if (result.paymentIntent.status === 'succeeded') {
    //    // Payment succeeded
     // }
    //}
  }; // Closing bracket for handleSubmit function

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Payment Details
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <label htmlFor="card-element">
              Credit or Debit Card
            </label>
            <CardElement id="card-element" onChange={handleChange} />
            {error && (
              <Typography color="error" role="alert">
                {error}
              </Typography>
            )}
          </Box>
          <Button variant="contained" color="primary" type="submit">
            Submit Payment
          </Button>
	  {paymentStatus && <p>{paymentStatus}</p>}
        </form>
      </CardContent>
    </Card>
  );
};

