import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const [loading, setLoading] = useState(true);

  const history = useHistory();
  
  const [username, setUserName] = useState('');
  
  const [initials, setInitials] = useState('');

  const loginUser = async (username, password) => {
    const response = await fetch("https://beta.omnihelpfulai.com/home/token/obtain/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "username":username,
        "password":password
      })
    });
    const data = await response.json();
    const derivedinitials = `${username}`.substring(0, 2).toUpperCase();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      setUserName(username);
      setInitials(derivedinitials);
      console.log("Checking jwt decode results")
      console.log(data)
      console.log(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      localStorage.setItem("username", username);
      localStorage.setItem("initials",derivedinitials);
      console.log(localStorage);
      alert("You are logged in.");
      history.push("/protected");
    } else {
      alert("Something went wrong!");
    }
  };

  const registerUser = async (firstname, lastname, email, username, password) => {
    const response = await fetch("https://beta.omnihelpfulai.com/home/user/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
	"firstname":firstname,
	"lastname":lastname,
        "email":email,
        "username":username,
        "password":password
      })
    });
    if (response.status === 201) {
      history.push("/login");
      alert("You are registered.");
    } else {
      alert("Something went wrong!");
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    history.push("/");
  };

  const contextData = {
    user,
    setUser,
    username,
    setUserName,
    initials,
    setInitials,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
