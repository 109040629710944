import { useContext } from "react";
import { useState } from 'react';
import AuthContext from "../context/AuthContext";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {LinkedInLoginButton} from "react-social-login-buttons";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import {LinkedIn} from 'react-linkedin-login-oauth2';
const theme = createTheme();



const LoginPage = () => {
  const { loginUser } = useContext(AuthContext);
  
  const handleFailure = (e) => {
    console.log("In Failure");
    alert(e);
    };

  const handleSuccess = async (e) => {
    console.log("In success");
    console.log(e);
    console.log("Auth token printed");
    try {
      const response = await fetch("/home/linkedin/", {
        method: "POST",
        body: JSON.stringify({ 'auth_token': e }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });

       const res = await response.json();
       console.log("Got user details from backend.")
       console.log(res);
       const username = res['email']
       const authtoken = res['auth_token']
       //username.length > 0 && loginUser(username, authtoken);
      } catch (error) {
       handleFailure(error);
     }
     };


  const { linkedInLogin } = useLinkedIn({
    clientId: 'test',
    redirectUri: `http://testurl:3000/linkedin`,
    onSuccess: () => handleSuccess(),
    onError: () => handleFailure(),
    scope: "email profile openid",
    state: "foobar"}
  );


  const handleSubmit = e => {
    e.preventDefault();
    const username = e.target.email.value;
    const password = e.target.password.value;
    username.length > 0 && loginUser(username, password);
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >	       
	  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
	  <Typography component="h1" variant="h5">
            Welcome! Sign in!
          </Typography>
       
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
	     
        
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />


           {/*<FormControlLabel control={<Checkbox value="remember" color="primary" />}label="Remember me TO DO"/>*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>


            <Grid container>
              <Grid item xs>
	        {/*<Typography component={Link} to="/" variant="body2">
                Forgot password? TO DO
        </Typography>*/}
              </Grid>
              <Grid item>
	        <Typography component={Link} to="/register" variant="body2">
                Don't have an account? Sign Up
               </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;

