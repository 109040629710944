import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginPage from "./loginPage";
const theme = createTheme();

const Home = () => {
	return (
		<section>
		<ThemeProvider theme={theme}>
                <CssBaseline />
		<Box
                  sx={{
                  bgcolor: 'background.paper',
                  pt: 8,
                  pb: 6,
                  }}
                >
    <LoginPage/>
		</Box>
		</ThemeProvider>
		</section>
	);
};
	
export default Home;
	
