import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

import AddIcon from '@mui/icons-material/Add';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SendIcon from '@mui/icons-material/Send';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import TimelineIcon from '@mui/icons-material/Timeline';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Button from '@material-ui/core/Button';
import UserInfo from "../user/UserInfo";
import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import CheckoutForm from "./CheckoutForm";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js/pure";

const actions = ['History', 'Ingest', 'Reset', 'Save', 'Share'];

const stripePromise = loadStripe("pk_test_51MmijtLpbJCaSbiTElw1GbZpdk7YKU3cfUs8iqVJuZPAaqAIP3to65KTvLmA62d1XKLvt34Ze7yO4Llymmasu3nS00xShHlSDp");

export default function TopAppBar() {
  const { user, logoutUser } = useContext(AuthContext);
  const { authTokens } = useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [newChat, setNewChat] = useState(false);
  const {initials} = useContext(AuthContext);
  const {username} = useContext(AuthContext);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  

  const handleOpenNavMenu = (event) => {
    console.log("open menu")
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    console.log("open settings")
    setAnchorElUser(event.currentTarget);
  };
  
  const handleCloseNavMenu = () => {
    console.log("close menu")
    setAnchorElNav(null);
  };
  
  const handleCloseUserMenu = () => {
    console.log("close settings")
    setAnchorElUser(null);
  };
  
  const  handleUpgradeClick = () => {
    setUpgradeDialogOpen(true);
  };

  const handleUpgradeDialogClose = () => {
    setUpgradeDialogOpen(false);
  };


  const  handleProfileClick = () => {
    setProfileDialogOpen(true);
  };

  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
  };


  return (
	  <Box sx={{ flexGrow: 0 }}>
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Reincarnate
      </Typography>

      {user && (
        <>
          <Tooltip title="Settings">
            <IconButton color="inherit" size="large" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: "transparent", size: "small" }}>{initials}</Avatar>
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: '45px' }}
            id="settings-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleProfileClick}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={handleUpgradeClick}>
              <ListItemIcon>
                <CardMembershipIcon fontSize="small" />
              </ListItemIcon>
              Upgrade
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={logoutUser}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

          {/* Add Dialog for Upgrade */}
          <Dialog open={upgradeDialogOpen} onClose={handleUpgradeDialogClose} maxWidth="md" fullWidth>
            <DialogTitle>Upgrade to Pro</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Access advanced and domain-specific agents for xxx USD/month.
              </DialogContentText>
              <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpgradeDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Add Dialog for Profile */}
          <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose}>
            <DialogContent>
              <p>Email: {username}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleProfileDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Toolbar>
  </AppBar>
</Box>


  );
}

