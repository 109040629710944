const generateUserPrompt = (question, context) => {
  const userprompt = `
    You will receive a question, and evidence from undergraduate lab manual. Answer only the question below given the following evidence. 
    Always cite evidence as page numbers. Add Related Questions. Format your answer in neat markdown. Use bold, italics, bullets, font size to stress kep points.
    
    **Input:** ${question}

    **Evidence:** ${context}

    **Source**

    **Answer**

    **Related Questions**
  `;
  
  return userprompt;
};

export default generateUserPrompt;
