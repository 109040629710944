import React from 'react';
import { Paper, Box, Grid, Avatar, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../static/images/OmniBig.png';
import gfm from 'remark-gfm';
import RenderContent from '../utils/render/ContentRenderer';
import ReactMarkdown from 'react-markdown'


const useStyles = makeStyles((theme) => ({
  logoThumbnail: {
    width: 40, // Adjust the width as needed
    height: 'auto',
  },
}));

function History({ openplots=false, history}) {
  const classes = useStyles();

  const storedInitials = localStorage.getItem("initials");
  const initials = storedInitials ? storedInitials : ``;
  
  const divStyle = {
    maxHeight: '72vh',
    overflow: 'auto',
    margin: 'auto',
    width: openplots ? '33%' : '66%', // Set width based on openplots value
    float: openplots ? 'left' : 'none', // Float left if openplots is true
  };


  return (
    <div style={divStyle}>
      <Paper style={{ backgroundColor: "white", border: "1px solid lightgrey" }}>
       
        <Grid container spacing={2} justifyContent="center" alignItems="center" maxWidth="xl">
          {history.map((entry, index) => {
            const isInput = index % 2 === 0;
            const content = Object.values(entry)[0];

            return (
              <Grid item xs={12} key={index}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {isInput ? (
                     <Avatar sx={{bgcolor: "black",size: "small"}}>{initials}</Avatar>
                    ) : (
                      <img src={logo} alt="Logo Thumbnail" className={classes.logoThumbnail} />
                    )}
                  </Grid>
                  <Grid item style={{ overflow: 'auto' }}>                
                  <ReactMarkdown children={content} remarkPlugins={[gfm]}/>     
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
	
      </Paper>
    </div>
  );
}

export default History;
