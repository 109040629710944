import React from 'react';
import { Button, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography } from '@mui/material';

const RenderContent = ({data}) => {
  const lines = data.trim().split('\n');

  // Check if the content represents a table
  if (lines.length >= 2 && lines[1].includes('|')) {
    const headers = lines[1].split('|').map(header => header.trim());
    const rows = lines.slice(2).map(row => row.split('|').map(cell => cell.trim()));

    // Create a CSV string from the table data
    const csvContent = [headers.join(',')].concat(rows.map(row => row.join(','))).join('\n');

    // Download function for the CSV file
    const downloadCSV = () => {
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'table.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    return (
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => <TableCell key={index}>{header}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => <TableCell key={cellIndex}>{cell}</TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button variant="contained" color="primary" onClick={downloadCSV}>Download CSV</Button>
      </Paper>
    );
  }

  // If it's not a table, render the content as plain text
  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="body1">{data}</Typography>
    </Paper>
  );
};


export default RenderContent;
