const addMemoryFunctionList = [
  {
    name: "doCustomDataSearch",
    description: "Search the uploaded databases to get the most recent data.",
    parameters: {
      type: "object",
      properties: {
        data: {
          type: "object",
          properties: {
            inputquery: {
              type: "string",
              description: "The input query sentence by the user."
            },
          },
          required: ["inputquery"]
        }
      }
    }
  }
];

export { addMemoryFunctionList };
